import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";

function OpenDocSignUrlPopup({ show, handleClose, data = {}, url = "", callBackStatusApi = () => {}, }) {
  const signUrl = url ? url : data?.signContract;
  return (
    <>
      <Modal
        show={show}
        onHide={() => (handleClose(),callBackStatusApi(data?._id))}
        style={{ backdropFilter: "blur(5px)" }}
        size="xl"
        centered
        backdrop="static"
        keyboard="false"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            Document
            <b className="text-capitalize">
              {data?.docMasterId?.documentType
                ? data?.docMasterId?.documentType
                : ""}{" "}
            </b>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={" container"}>
            <div className={" signZy-iFrame "} style={{ height: "32rem" }}>
              <iframe
                src={signUrl}
                className={`my-2`}
                alt=""
                width={"100%"}
                height={"100%"}
                title="doc-signZy-iFrame"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default OpenDocSignUrlPopup;
