export const BASECONFIG = {
  BASE_URL: process.env.REACT_APP_BACKENDURL_BASE_URL,
  BASE_URL_USER: process.env.REACT_APP_BACKENDURL_USER,
  BASE_URL_ETH: process.env.REACT_APP_BACKENDURL_ETH,
  BASE_URL_PROJECT: process.env.REACT_APP_BACKENDURL_PROJECT,
  BASE_URL_COMMON: process.env.REACT_APP_BACKENDURL_COMMON,
  AWS_URL: process.env.REACT_APP_AWS_BACKENDURL,
  VIDEO_URL_AWS:process.env.REACT_APP_AWS_VIDEO_URLS,
  LIVE_URL: process.env.REACT_APP_LIVE_URL,
  BASE_URL_DASHBOARD: process.env.REACT_APP_BACKENDURL_DASHBOARD,
  BASE_VERSION: process.env.REACT_APP_VERSION,
  REACT_APP_BASEURL_V2: process.env.REACT_APP_BASEURL_V2,
  REACT_APP_NOTIFICATION_BASEURL_V2: process.env.REACT_APP_NOTIFICATION_BASEURL_V2,
  REACT_APP_DOCUMENT_BASEURL_V2: process.env.REACT_APP_DOCUMENT_BASEURL_V2,
  REACT_APP_PAYMENT_BASEURL_V2: process.env.REACT_APP_PAYMENT_BASEURL_V2,
  REACT_APP_PROJECT_BASEURL_V2: process.env.REACT_APP_PROJECT_BASEURL_V2,

};
