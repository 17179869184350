import React from "react";
import ReactDOM from "react-dom/client";
import "react-multi-carousel/lib/styles.css";
import "./index.css";
import App from "./App";
import Loading from "react-fullscreen-loading";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/storeIndex";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ScrollToTop from "./Scrolltop";
import { BrowserRouter } from "react-router-dom";
import { Suspense } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import "bootstrap/dist/css/bootstrap.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
const CLIENT_ID = process.env.REACT_APP_GOOGLE_OUTH_CLIENT_ID;
root.render(
  <GoogleOAuthProvider clientId={CLIENT_ID}>
    <Provider store={store}>
      <React.StrictMode>
        <BrowserRouter>
          <ScrollToTop />
          <Suspense
            fallback={
              <div>
                <Loading
                  loading={true}
                  background="#dee2e6"
                  loaderColor="#3498db"
                />
              </div>
            }
          >
            <App />
          </Suspense>
        </BrowserRouter>
      </React.StrictMode>
    </Provider>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
