/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { FaArrowDown, FaArrowUp, FaFileSignature } from "react-icons/fa";

import Select from "react-select";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { apiURl } from "../../../store/actions";
import { API, makeSearchString } from "../../../apiwrapper";
import { SetloaderData } from "../../../store/reducer";
import { BASECONFIG } from "../../../config";
import { toast } from "react-toastify";
import AllProjectsList from "../../Modal/AllProjectsList";
import { truncateSlice } from "../../../Validation/InputValidation";
import AddCommentPopup from "./AddCommentPopup";
import { BsThreeDotsVertical } from "react-icons/bs";
import AllDocumentList from "../../Modal/AllDocumentList";
import { Pagination } from "../../Pagination/Pagination";
import { MdOutlineAttachFile, MdOutlineFilePresent } from "react-icons/md";
import DocumentCard from "./DocumentCard";
import DocumentFillterning from "./DocumentFillterning";
import OpenDocSignUrlPopup from "../../Modal/OpenDocSignUrlPopup";
import ViewDescriptionPopup from "./ViewDescriptionPopup";
import CustomePagination from "../../../hooks/CustomePagination";

import { Button, OverlayTrigger, Popover } from 'react-bootstrap';

function RequestedDocuments() {
  const [openIndex, setOpenIndex] = useState(-1);
  const tableRef = useRef();
  const dispatch = useDispatch();
  const { authUser } = useSelector((state) => state);
  const [showDescPopup, setShowDescPopup] = useState(false);

  const [viewProject, setViewProject] = useState(false);
  const [viewDoc, setViewDoc] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectData, setSelectData] = useState(null);
  const [forApproval, setForApproval] = useState(null);
  const [projects, setProjects] = useState([]);
  const [sortType, setSortType] = useState("");
  const [sortIndex, setSortIndex] = useState(-1);
  const [listingData, setListingData] = useState([]);
  const [data, setData] = useState({});
  const [selectedStartdate, setSelectedStartdate] = useState("");
  const [selectedEnddate, setSelectedEnddate] = useState("");
  const [showSignDoc, setShowSignDoc] = useState(false);
  const [signUrl, setSignUrl] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [filterVal, setFilterVal] = useState({
    searchKey: "",
    startDate: "",
    endDate: "",
    pageNo: 1,
    perPage: 10,
    projectIds: "",
    status: "",
    requestType: "Request",
  });

  const handleFilterChange = (e,name) => {
    setFilterVal({ ...filterVal, [name]: e.target.value, pageNo: filterVal.pageNo });
  };
  
  const handleOndatechange = (e,name) => {
    setFilterVal({...filterVal,[name]: e,pageNo: filterVal.pageNo,});
  };


  const handlePageChange = (page,value) => {
    setFilterVal({ ...filterVal, pageNo: value });
  };
  const handlePerRowsChange = async (e) => {
    setFilterVal({ ...filterVal, perPage: e.target.value });

  };

  const handlePerPagesChange = async (perPageLimit) => {
    setFilterVal({ ...filterVal, perPage: perPageLimit });
  };

  const fetchListingData = async () => {
    try {
      let url = `${apiURl.updateUser}/work/flow`;
      const searchString = makeSearchString(filterVal);
      url = searchString ? `${url}?${searchString}` : url;
      dispatch(SetloaderData(true));
      const data = await API({
        base_url: BASECONFIG.REACT_APP_BASEURL_V2,
        url: `${url}`,
        method: "GET",
        dispatch,
      });

      if (data?.status || data?.status === "true") {
        setData(data);
        setListingData(data?.data);
        setTotalRows (Math.ceil(Number(data?.totalCount) / Number(data?.perPage)));

        setTotalCount(Number(data?.totalCount));

      } else {
        setData({});
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      dispatch(SetloaderData(false));
    }
  };

  useEffect(() => {
    fetchListingData();
  }, [filterVal]);

  const sortListings = (sortTitle, sType) => {
    const sortFunctions = {
      Date: (a, b) => moment(a?.createdAt) - moment(b?.createdAt),
      Document_Type: (a, b) =>
        a?.docMasterId?.documentType?.localeCompare(
          b?.docMasterId?.documentType
        ),
      Subject: (a, b) => a?.subject.localeCompare(b?.subject),
      Renewal: (a, b) =>
        a?.docMasterId?.renewal?.period?.localeCompare(
          b?.docMasterId?.renewal?.period
        ),
    };
    let newListingArr = [...listingData];
    const sortFn = sortFunctions[sortTitle];
    if (sortFn) {
      if (sType === "asc") {
        newListingArr.sort(sortFn);
      } else if (sType === "des") {
        newListingArr.sort((a, b) => sortFn(b, a));
      }
    }
    setListingData(newListingArr);
  };

  // filter
  const startDateFilter = (event) => {
    setSelectedStartdate(event);
    fetchListingData(event, "startDate");
  };
  const endDateFilter = (event) => {
    setSelectedEnddate(event);
    fetchListingData(event, "endDate");
  };
  const checkReadByUser = (obj) => {
    const status = Array.isArray(obj?.readBy)
      ? obj?.readBy?.some((ele) => ele?.user_id === authUser?.data?._id)
      : obj?.readBy;
    return status;
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        And here's some amazing content. It's very engaging. Right?
      </Popover.Body>
    </Popover>
  );
  const sendDocumentUser = (item) => {
    return item?.findIndex((ele) => (ele?.user_id === authUser?.data?._id  && ele?.isSigned) ) == -1 ? false : true;
  }
// Call the status API before retrieving the list
  const handleCheckDocSignStatus = async (workflow_id) => {
    let payload = {
      email: authUser?.data?.personalInfo?. contact?. email?.id ,
      workflow_id: workflow_id,
    };

    dispatch(SetloaderData(true));
    try {
      let url = `${apiURl.getSignStatus}`;
      const resp = await API({
        base_url: BASECONFIG.REACT_APP_DOCUMENT_BASEURL_V2,
        url: `${url}`,
        method: "POST",
        dispatch,
        body: { ...payload },
      });
      if (resp.status === true) {
        dispatch(SetloaderData(false));
        // setSignedDoc(resp?.data);
      }else{
        dispatch(SetloaderData(false));
      }
    } catch (error) {
      dispatch(SetloaderData(false));
    }
  };

  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenIndex(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {showSignDoc ? (
        <OpenDocSignUrlPopup
          show={showSignDoc}
          handleClose={() => {
            setShowSignDoc(false);
            setSignUrl("");
            fetchListingData();
          }}
          data={projects}
          url={signUrl}
          callBackStatusApi={handleCheckDocSignStatus}

        />
      ) : (
        ""
      )}
      {viewProject ? (
        <AllProjectsList
          show={viewProject}
          handleClose={() => {
            setViewProject(false);
            setProjects([]);
          }}
          data={projects}
        />
      ) : (
        ""
      )}
      {showDescPopup ? (
        <ViewDescriptionPopup
          show={showDescPopup}
          selectData={selectData}
          handleClose={() => {
            setShowDescPopup(false);
            setSelectData(null);
          }}
          callBackFunction={fetchListingData}
        />
      ) : (
        ""
      )}
      {viewDoc ? (
        <AllDocumentList
          show={viewDoc}
          handleClose={() => {
            setViewDoc(false);
            setProjects([]);
            fetchListingData()
          }}
          data={projects}
          callBackFunction={fetchListingData}
          callBackStatusApi={handleCheckDocSignStatus}
          handleOpenSignUrl={(url) => {
            setSignUrl(url);
            setShowSignDoc(true);
          }}
        />
      ) : (
        ""
      )}
      {showPopup ? (
        <AddCommentPopup
          show={showPopup}
          selectData={selectData}
          handleClose={() => {
            setShowPopup(false);
            setSelectData(null);
            setForApproval(false);
          }}
          callBackFunction={fetchListingData}
          forApproval={forApproval}
        />
      ) : (
        ""
      )}
      <DocumentCard documentType={"Request"} />
      <div className="row card justify-content-end">
        {/* <div className="col-md-4 col-xs-12 mt-2">
          <div className="form-group">
            <Select
              name="projectIds"
              options={allProjects?.map((ele) => ({
                ...ele,
              }))}
              isClearable
              isMulti
              placeholder="Select Project"
              getOptionLabel={(options) => `${options["title"]}`}
              getOptionValue={(options) => options["_id"]}
              value={
                allProjects?.length > 1 &&
                allProjects?.filter((ele) =>
                  filterVal?.projectIds?.split(",")?.includes(ele._id)
                )
              }
              onChange={(event) => {
                let _idArr = event?.map((ele) => ele._id)?.join(",");
                setFilterVal({
                  ...filterVal,
                  projectIds: _idArr,
                });
              }}
            />
          </div>
        </div> */}
        <DocumentFillterning
          documentFillter={"Request"}
          handleOnchange={handleFilterChange}
          value={filterVal}
          handleOndatechange={handleOndatechange}
        />
        <div className="card-content">
          <hr className="hr" />
          <div className="responsiveTable">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col" className="tableHeading">
                    Date&Time
                    {sortIndex === 1 ? (
                      sortType === "asc" ? (
                        <FaArrowUp
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Date",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      ) : (
                        <FaArrowDown
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Date",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      )
                    ) : (
                      <img
                        className="mx-1"
                        src={"../Image/shotBy.svg"}
                        alt=""
                        onClick={() => {
                          setSortIndex(1);
                          setSortType("asc");
                          sortListings("Date", "asc");
                        }}
                      />
                    )}{" "}
                  </th>

                  <th scope="col" className="tableHeading">
                    Document Type
                    {sortIndex === 3 ? (
                      sortType === "asc" ? (
                        <FaArrowUp
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Document_Type",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      ) : (
                        <FaArrowDown
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Document_Type",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      )
                    ) : (
                      <img
                        className="mx-1"
                        src={"../Image/shotBy.svg"}
                        alt=""
                        onClick={() => {
                          setSortIndex(2);
                          setSortType("asc");
                          sortListings("Document_Type", "asc");
                        }}
                      />
                    )}{" "}
                  </th>
                  {/* <th scope="col" className="tableHeading">
                    Status
                    {sortIndex === 4 ? (
                      sortType === "asc" ? (
                        <FaArrowUp
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Status",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      ) : (
                        <FaArrowDown
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Status",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      )
                    ) : (
                      <img
                        className="mx-1"
                        src={"../Image/shotBy.svg"}
                        alt=""
                        onClick={() => {
                          setSortIndex(2);
                          setSortType("asc");
                          sortListings("Status", "asc");
                        }}
                      />
                    )}{" "}
                  </th> */}
                   <th scope="col" className="tableHeading">
                    Actions
                    {/* {sortIndex === 5 ? (
                      sortType === "asc" ? (
                        <FaArrowUp
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Action_Type",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      ) : (
                        <FaArrowDown
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Action_Type",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      )
                    ) : (
                      <img
                        className="mx-1"
                        src={"../Image/shotBy.svg"}
                        alt=""
                        onClick={() => {
                          setSortIndex(5);
                          setSortType("asc");
                          sortListings("Action_Type", "asc");
                        }}
                      />
                    )}{" "} */}
                  </th>
                  <th scope="col" className="tableHeading">
                    Reference
                    {sortIndex === 5 ? (
                      sortType === "asc" ? (
                        <FaArrowUp
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Reference",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      ) : (
                        <FaArrowDown
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Reference",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      )
                    ) : (
                      <img
                        className="mx-1"
                        src={"../Image/shotBy.svg"}
                        alt=""
                        onClick={() => {
                          setSortIndex(2);
                          setSortType("asc");
                          sortListings("Reference", "asc");
                        }}
                      />
                    )}{" "}
                  </th>

                  {/* <th scope="col" className="tableHeading">
                    Subject
                    {sortIndex === 3 ? (
                      sortType === "asc" ? (
                        <FaArrowUp
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Subject",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      ) : (
                        <FaArrowDown
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Subject",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      )
                    ) : (
                      <img
                        className="mx-1"
                        src={"../Image/shotBy.svg"}
                        alt=""
                        onClick={() => {
                          setSortIndex(3);
                          setSortType("asc");
                          sortListings("Subject", "asc");
                        }}
                      />
                    )}{" "}
                  </th> */}

                  {/* <th scope="col" className="tableHeading">
                    Renewal
                    {sortIndex === 4 ? (
                      sortType === "asc" ? (
                        <FaArrowUp
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Renewal",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      ) : (
                        <FaArrowDown
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Renewal",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      )
                    ) : (
                      <img
                        className="mx-1"
                        src={"../Image/shotBy.svg"}
                        alt=""
                        onClick={() => {
                          setSortIndex(4);
                          setSortType("asc");
                          sortListings("Renewal", "asc");
                        }}
                      />
                    )}{" "}
                  </th> */}

                 
                  {/* 
                  <th scope="col" className="tableHeading">
                    Project */}
                  {/* {sortIndex === 6 ? (
                      sortType === "asc" ? (
                        <FaArrowUp
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Project",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      ) : (
                        <FaArrowDown
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Project",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      )
                    ) : (
                      <img
                        className="mx-1"
                        src={"../Image/shotBy.svg"}
                        alt=""
                        onClick={() => {
                          setSortIndex(6);
                          setSortType("asc");
                          sortListings("Project", "asc");
                        }}
                      />
                    )}{" "} */}
                  {/* </th> */}

                  <th scope="col" className="tableHeading">
                   More Actions
                    {/* {sortIndex === 6 ? (
                      sortType === "asc" ? (
                        <FaArrowUp
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Action",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      ) : (
                        <FaArrowDown
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Action",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      )
                    ) : (
                      <img
                        className="mx-1"
                        src={"../Image/shotBy.svg"}
                        alt=""
                        onClick={() => {
                          setSortIndex(6);
                          setSortType("asc");
                          sortListings("Action", "asc");
                        }}
                      />
                    )}{" "} */}
                  </th>
                </tr>
              </thead>

              <tbody>
                {listingData?.length > 0 ? (
                  listingData?.map((row, index) => {
                    return (
                      <tr className="borderLine">
                        <td valign="middle">
                          {row.createdAt
                            ? moment(row.createdAt)
                                .local()
                                .format("DD/MM/YYYY hh:mm a")
                            : ""}
                        </td>
                      
                        <td valign="middle">
                          {row?.docMasterId?.documentType
                            ? row?.docMasterId?.documentType
                            : ""}
                        </td>
                        {/* <td valign="middle">
                          {row?.docMasterId?.status
                            ? row?.docMasterId?.status
                            : "---"}
                        </td> */}
                          <td valign="middle">
                          {sendDocumentUser(row?.sendDocByUser) ? (
                            <span
                              className="text-primary "
                              onClick={() => {
                                setProjects(row);
                                setViewDoc(true);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <a>Click here</a>{" "}
                              {/* {row?.sendDocByUser?.[0]?.documents
                                ?.split(".")
                                ?.pop()
                                ?.toLowerCase()} */}
                              {row?.sendDocByUser?.length > 1 ? (
                                <span>+{row?.sendDocByUser?.length - 1} </span>
                              ) : (
                                ""
                              )}
                            </span>
                          ) : (
                            <span
                              className="text-primary "
                              onClick={() => {
                                setProjects(row);
                                setViewDoc(true);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {row?.docMasterId?.isDigitalSignatureRequire ? (
                                <FaFileSignature
                                  color="blue"
                                  title="digital signature require"
                                  size={15}
                                  style={{ cursor: "pointer" }}
                                />
                              ) : (
                                ""
                              )}{" "}
                              <MdOutlineAttachFile
                                color="blue"
                                title="Upload file"
                                size={20}
                                style={{ cursor: "pointer" }}
                              />
                            </span>
                          )}
                        </td>
                        <td valign="middle">
                          {/* <p
                            title={row?.projectIds
                              ?.map((ele) => ele?.title)
                              ?.join(",")}
                          >
                            {truncateSlice(`${row?.projectIds[0]?.title}`, 6)}{" "}
                            <span
                              className="text-primary"
                              onClick={() => {
                                setProjects(row?.projectIds);
                                setViewProject(true);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {row?.projectIds.length > 1
                                ? `+${row?.projectIds.length - 1}`
                                : ""}
                            </span>
                          </p> */}
                          <p>{row?.subject}</p>
                        </td>

                        {/* <td valign="middle">
                          {row?.subject ? row?.subject : ""}
                        </td> */}

                        {/* <td valign="middle">
                          {row?.docMasterId?.renewal?.isRenewal
                            ? row?.docMasterId?.renewal?.period
                            : ""}
                        </td> */}

                      
                        {/* <td valign="middle" align="center">
                          <p
                            title={row?.projectIds
                              ?.map((ele) => ele?.title)
                              ?.join(",")}
                          >
                            {truncateSlice(`${row?.projectIds[0]?.title}`, 6)}{" "}
                            <span
                              className="text-primary"
                              onClick={() => {
                                setProjects(row?.projectIds);
                                setViewProject(true);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {row?.projectIds.length > 1
                                ? `+${row?.projectIds.length - 1}`
                                : ""}
                            </span>
                          </p>
                        </td> */}
                        <td valign="middle">
                          <>
                            <div
                              className="div-wrapper customeActionButton"
                              onClick={() =>
                                setOpenIndex(openIndex === index ? -1 : index)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {/* satya */}
                              <img src="../Image/group1171276003.png" alt="" style={{maxWidth:"30px"}}/>
                              {/* <BsThreeDotsVertical size={25}  className="actionDot" /> */}

                              <div className="faEllipsisVertical customeEllipsisVertical">
                              <div
                                className="divOpenClose"
                                onClick={() =>
                                  setOpenIndex(openIndex === index ? -1 : index)
                                }
                              >
                                {openIndex === index && (
                                  <div ref={ref} style={{padding:"0px 10px"}}>
                                    {row?.isNoActionAllowed ? (
                                      <Link to={`#`}>
                                        No Action Allowed
                                        <br />
                                      </Link>
                                    ) : (
                                      ""
                                    )}

                                    <Link
                                      to={"#"}
                                      onClick={() => {
                                        setSelectData(row);
                                        setShowDescPopup(true);
                                      }}
                                    >
                                      View Description
                                      <br />
                                    </Link>

                                    {row?.isCommentAllowed ? (
                                      <Link
                                        to={"#"}
                                        onClick={() => {
                                          setSelectData(row);
                                          setShowPopup(true);
                                        }}
                                        className={
                                          row?.comments?.length === 0
                                            ? ""
                                            : "text-secondary"
                                        }
                                      >
                                        Add/View Comment
                                        <br />
                                      </Link>
                                    ) : (
                                      ""
                                    )}
                                    {row?.isApprovalAllowed ? (
                                      <Link
                                        to={`#`}
                                        onClick={() => {
                                          setSelectData(row);
                                          setShowPopup(true);
                                          setForApproval(true);
                                        }}
                                        className={
                                          row?.approval?.length === 0
                                            ? ""
                                            : "text-secondary"
                                        }
                                      >
                                        Approve/Reject
                                        <br />
                                      </Link>
                                    ) : (
                                      ""
                                    )}
                                    {row?.isReadReceiptAllowed ? (
                                      <Link
                                        to={`#`}
                                        onClick={() => {
                                          const isRead = checkReadByUser(row);
                                          if (!isRead) {
                                            setProjects(row);
                                            setViewDoc(true);
                                          }
                                        }}
                                        className={
                                          !checkReadByUser(row)
                                            ? ""
                                            : "text-secondary"
                                        }
                                      >
                                        Send Read Receipt
                                        <br />
                                      </Link>
                                    ) : (
                                      ""
                                    )}
                                    {!row?.isApprovalAllowed &&
                                      !row?.isCommentAllowed &&
                                      !row?.isNoActionAllowed &&
                                      !row?.isReadReceiptAllowed && (
                                        <Link to={`#`}>
                                          No Action Allowed
                                          <br />
                                        </Link>
                                      )}
                                  </div>
                                )}
                              </div>
                            </div>
                            </div>

                           
                          </>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colspan="8" className="text-center text-danger">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

        <CustomePagination handlePageChange={handlePageChange} currentPage={filterVal.pageNo} totalRows={totalRows} handlePerRowsChange={handlePerRowsChange} perPage={filterVal.perPage} totalCount={totalCount}/>

   
    </>
  );
}

export default RequestedDocuments;
