/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./Responsive.css";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { API } from "./apiwrapper";
import {
  SetAddNewProjectData,
  SetCountryCodes,
  SetDisableCountry,
  SetloaderData,
} from "./store/reducer";
import { useDispatch, useSelector } from "react-redux";
import React, { lazy, memo, useEffect, useState } from "react";
import { SetKycData, SetPreKycData } from "./store/reducer/kycData";
import { SetIndustry } from "./store/reducer/industoryData";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import { ToastContainer, toast } from "react-toastify";
import { SetPasswordToken } from "./store/reducer/passwordToken";
import apiURl from "./store/actions/api-url";
import authUser, {
  LogoutUser,
  SetAuthUserData,
} from "./store/reducer/authUser";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { setFcmToken } from "./store/reducer/fcmToken";
import { BASECONFIG } from "./config";
import { LogoutGoogleUser } from "./store/reducer/authGoogleUser";
import { Button, Dropdown, Modal } from "react-bootstrap";
import axios from "axios";
import { Suspense } from "react";
import MabrookTeam from "./components/About/MabrookTeam";
import InboxExpand from "./components/Dashboard/SecureMessage/inboxExpand";
import Composemessage from "./components/Dashboard/SecureMessage/composemsg";

import OpenModal from "./components/Modal/OpenModal";
import { getCurrentLocation, logOutApi } from "./config/GetCurrentLocation";
import NewDocumentManagement from "./components/Dashboard/DocumentManagement/NewDocumentManagement";
import TockenomicsOverView from "./components/projectTabs/tockenomicsOverView.js";
import Cookies from 'js-cookie';

const Layout = lazy(() => import("./components/Layout/Layout"));
const Homepage = lazy(() => import("./components/Pages/Homepage"));
const BrowseProject = lazy(() => import("./components/BrowseProject"));
const HowToInvest = lazy(() => import("./components/HowToInvest/index"));
const Contact = lazy(() => import("./components/Contact"));
const About = lazy(() => import("./components/About"));
const Privacypolicy = lazy(() => import("./components/privacyPolicy"));
const TermCondition = lazy(() => import("./components/termsCondition"));
const WhitePapers = lazy(() => import("./components/WhitePapers"));
const Blog = lazy(() => import("./components/Blog"));
const Login = lazy(() => import("./components/Login"));
const ChangePassword = lazy(() => import("./components/Login/changePassword"));
const RecoverPassword = lazy(() =>
  import("./components/Login/recoverPassword")
);
const Signin = lazy(() => import("./components/Signup"));
const BlogDetail = lazy(() => import("./components/Blog/BlogDetail"));
const FaqPage = lazy(() => import("./components/Faq"));
const Raisefund = lazy(() => import("./components/KycProcess/raiseFundForm"));
const Carddetail = lazy(() => import("./components/Pages/cardDetails"));
const ProjectDetailTab = lazy(() => import("./components/projectTabs"));
const ProjectDetailOverview = lazy(() =>
  import("./components/projectTabs/tabOverview")
);
const ProjectDetailUpdates = lazy(() =>
  import("./components/projectTabs/tabUpdates")
);
const ProjectDetailTeam = lazy(() =>
  import("./components/projectTabs/tabTeam")
);
const ProfileSetting = lazy(() =>
  import("./components/setting/ProfileSetting")
);
const InvestroSearch = lazy(() =>
  import("./components/Dashboard/InvestorSearch")
);
const ProjectManagmentDashBoard = lazy(() =>
  import("./components/Dashboard/projectManagement")
);
const CheckPreviewDashBoard = lazy(() =>
  import("./components/Dashboard/checkPreviewDashboad")
);
const Milestonedashboard = lazy(() =>
  import("./components/Dashboard/milestoneDashboard")
);
const Faqdashbaord = lazy(() => import("./components/Dashboard/faqDashboard"));
const AddnewprojectDashBoard = lazy(() =>
  import("./components/Dashboard/addNewProject")
);
const Documentsdashboard = lazy(() =>
  import("./components/Dashboard/documentsDashboard")
);
const Tokenmanagement = lazy(() =>
  import("./components/Dashboard/tokenmanagement")
);
const MintNewToken = lazy(() => import("./components/Dashboard/mintNewToken"));
const Blockunblock = lazy(() => import("./components/Dashboard/blockUnblock"));

const UnBlock = lazy(() => import("./components/Dashboard/Unblock"));
const Resumeaction = lazy(() => import("./components/Dashboard/resumeAction"));
const PopUpContent = lazy(() => import("./components/Dashboard/popUpContent"));
const Withdrawscreen = lazy(() =>
  import("./components/Dashboard/widthdrwaScreen")
);
const IsAlreadyLoggedIn = lazy(() => import("./hooks/IsAlreadyLoggedIn"));

const Sharergister = lazy(() => import("./components/Dashboard/shareregister"));
const KycLandingPage = lazy(() =>
  import("./components/KycProcess/kycLandingPage")
);
const RightPanelPackage = lazy(() =>
  import("./components/projectTabs/rightPanelPackage")
);
const MyDashBoard = lazy(() =>
  import("./components/Dashboard/IssuerDashbordModule/myDashBoard")
);
const Myportfolio = lazy(() => import("./components/Dashboard/myPortFolio"));
const DashBoardlayout = lazy(() =>
  import("./components/Dashboardlayout/dashboardlayout")
);

const KycProcess = lazy(() => import("./components/KycProcess"));
const MBRHTH = lazy(() => import("./components/Dashboard/mbrHTH"));
const Addsilverbutton = lazy(() =>
  import("./components/Dashboard/addsilverbundle")
);
const TransactionApproved = lazy(() =>
  import("./components/InvestorDashboard/TransactionApproved")
);

const MyWallet = lazy(() =>
  import("./components/Dashboard/MyWalletModule/MyWallet")
);
const MyInvestments = lazy(() =>
  import("./components/InvestorDashboard/MyInvestments")
);
const CommonPopups = lazy(() =>
  import("./components/InvestorDashboard/AllPopups/CommonPopups")
);
const Settings = lazy(() =>
  import("./components/Dashboard/SettingsModule/Settings")
);

const MyDocuments = lazy(() =>
  import("./components/InvestorDashboard/MyDocuments")
);
const CancelRefund = lazy(() =>
  import("./components/Dashboard/CancelRefundModule/CancelRefund")
);
const Dashboard = lazy(() => import("./components/InvestorDashboard"));

const MyCampaigns = lazy(() =>
  import("./components/InvestorDashboard/MyCampaigns")
);
const WithdrawAmount = lazy(() =>
  import("./components/InvestorDashboard/WithdrawAmount")
);
const AddFunds = lazy(() =>
  import("./components/Dashboard/AddFundProcess/AddFunds")
);

const Support = lazy(() => import("./components/Dashboard/Support"));
const MyIssuerDocuments = lazy(() =>
  import("./components/Dashboard/MyDocuments")
);
const LoaderComponent = lazy(() => import("./components/LoaderComponent"));
const Mailverification = lazy(() =>
  import("./components/Emailverification/mailverification")
);

const RequireAuth = lazy(() => import("./hooks/useAuth"));
const AlreadyLoggedIn = lazy(() =>
  import("./components/Pages/AlreadyLoggedIn")
);
const MyInvestmentsCompletedList = lazy(() =>
  import("./components/InvestorDashboard/MyInvestmentsCompletedList")
);
const InvestmentsCampaignDetails = lazy(() =>
  import("./components/InvestorDashboard/InvestmentCampaignDetails")
);

const Shariahcompliance = lazy(() =>
  import("./components/ShariahCompliance/Shariahcompliance")
);
const ScrollToTopButton = lazy(() =>
  import("./components/ScrolltoTop/ScrolltoTop")
);
const Auditlogdashboard = lazy(() =>
  import("./components/Dashboard/AuditlogModule/AuditlogDashboard")
);
const ResponceMessage = lazy(() =>
  import("./components/Modal/ResponceMessage")
);

const PreKycFormIndex = lazy(() =>
  import("./components/KycProcess/PreKycProcess/PreKycFormIndex")
);

const Notificationpage = lazy(() =>
  import("./components/InvestorDashboard/Notification/NotificationPage")
);

const Securemessage = lazy(() =>
  import("./components/Dashboard/SecureMessage/securemsg")
);

const InvestorDetailsIndex = lazy(() =>
  import("./components/Dashboard/InvestorDetails/InvestorDetailsIndex")
);
const DocumentManagementIndex = lazy(() =>
  import("./components/Dashboard/DocumentManagement/DocumentManagementIndex")
);

const ViewNotification = lazy(() =>
  import("./components/InvestorDashboard/Notification/ViewNotification")
);
const WorkflowDetails = lazy(() =>
  import("./components/Dashboard/DocumentManagement/WorkflowDetails.jsx")
);

const TIMEOUT_INTERVAL = 180000; // 3 minutes (180 seconds)
// const TIMEOUT_INTERVAL = 11180000; // 3 minutes (180 seconds)
const WARNING_DURATION = 30000; // 30 seconds

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const tokenGet= Cookies.get('token');
  let userToken = null;
  
  if (tokenGet) {
    try {
      userToken = JSON.parse(tokenGet);
    } catch (error) {
      userToken = null; // Provide a default value or handle the error as needed
    }
  } else {
    userToken = null; // Handle the case where getToken is undefined
  }

  const [firebaseToken, setFirebaseToken] = useState();
  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get("code");
  const [showRespModal, setRespModal] = useState(false);

  const [respMessage, setRespMessage] = useState({
    title: "",
    message: "",
    status: "",
    other: false,
  });
  // const jwtExpired = useSelector((state) => state.authUser?.data?.jwtExpired);

  // useEffect(() => {
  //   if (jwtExpired) {
  //     const delay = parseInt(jwtExpired) * 1000;
  //     const timerId = setTimeout(() => {
  //       handleLogout();
  //       toast.error("Your session has timed out. Please log in again.");
  //     }, delay);
  //     return () => {
  //       clearTimeout(timerId);
  //     };
  //   }
  // }, [jwtExpired]);

  const [lastActivity, setLastActivity] = useState(Date.now());
  const [showWarning, setShowWarning] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(WARNING_DURATION);

  const resetTimer = () => {
    setLastActivity(Date.now());
    setShowWarning(false);
    setTimeRemaining(WARNING_DURATION);
  };

  const handleActivity = () => {
    if (!showWarning) {
      resetTimer();
    }
  };

  const handleLogout = () => {
    setShowWarning(false);
    dispatch(logOutApi(authUser?.data));
    setTimeout(() => {
      navigate("/");
    }, 500);
  };

  // const eventTypes = [
  //   "mousemove",
  //   "keydown",
  //   "click",
  //   "scroll",
  //   "touchmove",
  //   "visibilitychange",
  // ];

  // useEffect(() => {
  //   eventTypes.forEach((eventType) => {
  //     window.addEventListener(eventType, handleActivity);
  //   });

  //   return () => {
  //     eventTypes.forEach((eventType) => {
  //       window.removeEventListener(eventType, handleActivity);
  //     });
  //   };
  // }, [showWarning]);

  // useEffect(() => {
  //   if (!userToken) {
  //     // Do not execute this logic if the user is not logged in
  //     //check
  //     return;
  //   }
  //   const interval = setInterval(() => {
  //     if (Date.now() - lastActivity >= TIMEOUT_INTERVAL && !showWarning) {
  //       setShowWarning(true);
  //     }
  //     if (showWarning) {
  //       setTimeRemaining((prevTime) => {
  //         if (prevTime <= 0) {
  //           clearInterval(interval);
  //           handleLogout();
  //           return 0; // Prevent the timer from going into negative values
  //         } else {
  //           return prevTime - 1000;
  //         }
  //       });
  //     }
  //   }, 1000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [userToken, lastActivity, showWarning, timeRemaining]);

  const firebaseConfig = {
    apiKey: "AIzaSyC497xVZKoec3bZtNW-HXPDbr3imCDRt-4",
    authDomain: "almabrook-add80.firebaseapp.com",
    projectId: "almabrook-add80",
    storageBucket: "almabrook-add80.appspot.com",
    messagingSenderId: "1055471595107",
    appId: "1:1055471595107:web:2bb7f32488144fab7b9256",
    measurementId: "G-Z8DDZSHZXL",
  };

  const app = initializeApp(firebaseConfig);

  const messaging = getMessaging();
  getToken(messaging, {
    vapidKey:
      "BGLJnynuJB6mouJZPyXrqJQW6TRZLP5ScypOQ5JyWty6hBymtzHir397rabR1iOxR6Q6h9OgG-f3xGXCt2iqa4M",
  })
    .then((currentToken) => {
      if (currentToken) {
        setFirebaseToken(currentToken);
        dispatch(setFcmToken({ fcmToken: currentToken }));
      } else {
      }
    })
    .catch((err) => {
      // ...
    });
  dispatch(SetPasswordToken({ token: code }));
  const handleClose = () => {
    setRespModal(false);
    setRespMessage({
      title: "",
      message: "",
      status: "",
      other: false,
    });
  };

  const sendLinkedInCode = async () => {
    dispatch(SetloaderData(true));
    try {
      let currentLocation = await getCurrentLocation();
      const url = apiURl.getLogin;
      const payload = {
        // token: code,
        // fcmToken: firebaseToken,
        // type: "web",

        // "email":obj?.email,
        "password": "12345678",
         location: currentLocation,
        "channel": "sms",
        "linkedinToken": code

      };
      const data = await API({
        base_url: BASECONFIG.REACT_APP_BASEURL_V2,
        url: url,
        method: "POST",
        body: payload,
        formData: false,
        dispatch,
      });

      if (data?.status || data?.status === "true") {
        Cookies.set('accessToken', JSON.stringify( data?.accessToken ));
        Cookies.set('token', JSON.stringify( data?.token));
        dispatch(SetAuthUserData( data?.data));
        localStorage.removeItem("redirectPath");
        setRespModal(true);
        setRespMessage({
          title: `${"LinkedIn Login/Signup Status"}`,
          message: `You have linkedIn login/signup successfully, Do you want to update your profile now?`,
          status: data?.status,
          other: true,
        });
        // setTimeout(() => {
        //   navigate("/settings");
        // }, 2000);
      } else {
        setRespModal(true);
        setRespMessage({
          title: `${"LinkedIn Login/Signup Status"}`,
          message: data?.message,
          status: data?.status,
          other: true,
        });
      }
    } catch (err) {
    } finally {
      dispatch(SetloaderData(false));
    }
  };

  const fetchCountryCodes = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASEURL_V2 + `${apiURl.getCountryCode}`
      );
      dispatch(SetCountryCodes(response.data.data));
      let disableCountry = response?.data?.data?.filter((ele) => !ele?.status);
      dispatch(SetDisableCountry(disableCountry));
    } catch (error) {}
  };

  const fetchIndustry = async () => {
    try {
      let url = apiURl.documentCategories;
      await API({
        base_url: BASECONFIG.REACT_APP_DOCUMENT_BASEURL_V2,
        url: url,
        dispatch,
      }).then((data) => {
        if (data?.status || data?.status === "true") {
          dispatch(SetIndustry(data?.data));
        } else {
          dispatch(SetIndustry(data?.data));
        }
      });
    } catch (error) {
      throw Error(error);
    }
  };

  useEffect(() => {
    if (code) {
      sendLinkedInCode();
    }
    fetchCountryCodes();
    fetchIndustry();
    return () => {
      dispatch(SetloaderData(false));
    };
  }, []);

  return (
    <>
      {showRespModal ? (
        <ResponceMessage
          show={showRespModal}
          handleClose={() => handleClose()}
          title={respMessage.title}
          message={respMessage.message}
          status={respMessage.status}
          otherLogin={respMessage?.other || false}
        />
      ) : (
        ""
      )}
      <LoaderComponent />
      <OpenModal />
      <div className="App">
        <Routes>
          <Route element={<Layout />}>
            <Route index path="/" element={<Homepage />} />
            <Route
              element={<RequireAuth authorized={userToken ? true : false} />}
            >
              <Route path="/profileSetting" element={<ProfileSetting />} />
              <Route path="/raiseFundForm" element={<PreKycFormIndex />} />
              <Route path="/kycProcess" element={<KycProcess />} />
              <Route path="/pre_kyc" element={<KycLandingPage />} />
            </Route>

            <Route
              element={
                <IsAlreadyLoggedIn authorized={userToken ? true : false} />
              }
            >
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Signin />} />
              <Route path="/already_logged_in" element={<AlreadyLoggedIn />} />
            </Route>

            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/mabrookteam" element={<MabrookTeam />} />

            <Route path="/privacyPolicy" element={<Privacypolicy />} />
            <Route path="/faq" element={<FaqPage />} />
            <Route path="/termsCondition" element={<TermCondition />} />

            <Route path="/how_to_invest" element={<HowToInvest />} />
            <Route path="/browseproject" element={<BrowseProject />} />
            <Route path="/propertydetails/:id" element={<Carddetail />} />
            <Route path="/whitePapers" element={<WhitePapers />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blogDetail/:_id" element={<BlogDetail />} />
            <Route path="/shariahcompliance" element={<Shariahcompliance />} />
            <Route path="/tockenomicsoverview/:id" element={<TockenomicsOverView />} />
            {/* Outside Dashboard */}

            <Route path="/mailverification" element={<Mailverification />} />
            <Route path="/projectDetailTab" element={<ProjectDetailTab />} />
            <Route path="/tabOverview" element={<ProjectDetailOverview />} />
            <Route path="tabUpdates" element={<ProjectDetailUpdates />} />
            <Route path="tabTeam" element={<ProjectDetailTeam />} />
            <Route exact path="/linkedin" component={<LinkedInCallback />} />
            <Route path="/recoverPassword" element={<RecoverPassword />} />
            <Route
              path="/changePassword/:userId"
              element={<ChangePassword />}
            />

            <Route
              path="/projectDetailOverview"
              element={<ProjectDetailOverview />}
            />
            <Route path="/rightPanelPackage" element={<RightPanelPackage />} />
          </Route>

          <Route element={<DashBoardlayout />}>
            <Route
              element={<RequireAuth authorized={userToken ? true : false} />}
            >
              <Route
                index
                path="/dashboard"
                element={
                  authUser?.data?.userType?.isIssuer ? <MyDashBoard /> : <Dashboard />
                }
              />
              <Route path="/mydashboard" element={<MyDashBoard />} />
              <Route path="/investordashboard" element={<Dashboard />} />
              <Route
                path="/myinvestmentscompletedlist"
                element={<MyInvestmentsCompletedList />}
              />
              <Route
                path="/investmentCampaignDetails/:id"
                element={<InvestmentsCampaignDetails />}
              />
              <Route
                path="/transactionapproved/:id"
                element={<TransactionApproved />}
              />
              <Route
                path="/transactionapproved"
                element={<TransactionApproved />}
              />
              <Route path="/wallet" element={<MyWallet />} />
              <Route path="/sharergister" element={<Sharergister />} />
              <Route path="/investrosearch" element={<InvestroSearch />} />
              <Route
                path="/investordetails/:id/:type"
                element={<InvestorDetailsIndex />}
              />
              <Route path="/MyInvestments" element={<MyInvestments />} />
              <Route path="/commonpopups" element={<CommonPopups />} />
              <Route path="/mydocuments" element={<MyDocuments />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/auditlog/:_id" element={<Auditlogdashboard />} />
              <Route path="/cancelrefund" element={<CancelRefund />} />
              <Route path="/mycampaigns" element={<MyCampaigns />} />
              <Route path="/notification" element={<Notificationpage />} />
              <Route
                path="/notification/details/:id"
                element={<ViewNotification />}
              />
              <Route path="/securemessage" element={<Securemessage />} />
              <Route path="/inboxexpand" element={<InboxExpand />} />
              <Route path="/composemessage" element={<Composemessage />} />
              <Route path="/withdrawamount" element={<WithdrawAmount />} />
              <Route path="/addfunds" element={<AddFunds />} />
              <Route
                path="/project_management"
                element={<ProjectManagmentDashBoard />}
              />
              <Route
                path="/checkpreviewdashboard"
                element={<CheckPreviewDashBoard />}
              />
              <Route
                path="/project_management/add_new_project"
                element={<AddnewprojectDashBoard />}
              />
              <Route
                path="/project_management/add_new_project/:_id"
                element={<AddnewprojectDashBoard />}
              />

              <Route
                path="/document_management"
                // element={<DocumentManagementIndex />
                element={<NewDocumentManagement />
              }
              />
                <Route
                path="/managedocument/:requestType/document/:id"
                element={<WorkflowDetails />}
              />

              <Route
                path="/milestonedashboard"
                element={<Milestonedashboard />}
              />
              <Route path="/dashbord/kycProcess" element={<KycProcess />} />
              <Route path="/dashbord/pre_kyc" element={<KycLandingPage />} />
              <Route path="/investrosearch" element={<InvestroSearch />} />
              <Route
                path="/project_management"
                element={<ProjectManagmentDashBoard />}
              />
              <Route
                path="/checkpreviewdashboard"
                element={<CheckPreviewDashBoard />}
              />
              <Route
                path="/project_management/add_new_project"
                element={<AddnewprojectDashBoard />}
              />
              <Route path="/tokenmanagement" element={<Tokenmanagement />} />
              <Route path="/mbrhth" element={<MBRHTH />} />
              <Route path="/mintnewtoken" element={<MintNewToken />} />
              <Route path="/popupcontent" element={<PopUpContent />} />
              <Route
                path="/milestonedashboard"
                element={<Milestonedashboard />}
              />
              <Route path="/faqdashbaord" element={<Faqdashbaord />} />
              <Route
                path="/documentsdashboard"
                element={<Documentsdashboard />}
              />
              <Route
                path="/myinvestmentscompletedlist"
                element={<MyInvestmentsCompletedList />}
              />
              <Route path="/blockunblock" element={<Blockunblock />} />
              <Route path="/resumeaction" element={<Resumeaction />} />
              <Route path="/withdrawscreen" element={<Withdrawscreen />} />
              <Route path="/unblock" element={<UnBlock />} />
              <Route path="/addsilverbutton" element={<Addsilverbutton />} />
              <Route path="/support" element={<Support />} />
              <Route
                path="/issuermydocuments"
                element={<MyIssuerDocuments />}
              />
              <Route path="/myportfolio" element={<Myportfolio />} />
              <Route path="/investordetails/:id" element={<InvestroSearch />} />
              {/*Inside Dashboard satya */}
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/" element={<Dropdown />} />
        </Routes>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
      <Modal
        show={showWarning}
        centered
        backdrop="static"
        keyboard={false}
        style={{ backdropFilter: "blur(3px)" }}
      >
        <Modal.Body>
          <p>
            You are inactive and will be logged out in (
            {Math.ceil(timeRemaining / 1000)} Seconds).
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={resetTimer}>
            Stay Logged in
          </Button>
          {/* <Button variant="secondary" onClick={handleLogout} disabled>
            Logout ({Math.ceil(timeRemaining / 1000)} Seconds)
          </Button> */}
        </Modal.Footer>
      </Modal>
      <ScrollToTopButton />
    </>
  );
}

export default memo(App);
