import { createSlice } from "@reduxjs/toolkit";

export const sliceKycData = createSlice({
  name: "kycData",
  initialState: {
    data: null,
    pre_kyc: { personalInformation: {} },
  },
  reducers: {
    fetched: (state, action) => {
      state.data = action.payload.data;
    },

    pre_kyc: (state, { payload }) => {
      state.pre_kyc = payload.data;
    },
  },
});

export const { fetched, pre_kyc } = sliceKycData.actions;

export const SetKycData = (data) => (dispatch) => {
  if (!data) {
   
    return;
  }
  dispatch(fetched({ data }));
};
export const SetPreKycData = (data) => (dispatch) => {
  if (!data) {
   
    return;
  }
  dispatch(pre_kyc({ data }));
};

export default sliceKycData.reducer;
