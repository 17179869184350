/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
import { toast } from "react-toastify";
import { validationMessages } from "../store/actions/api-url";

export const getKycStatusText = (authUser, kycData) => {
  const { isIssuer, isInvestor } = authUser?.data?.userType || {};
  const {isKycCompleted , stepCompleted} = authUser?.data?.progress || {};
  const { companyStatus } = authUser?.data?.companyDetails || {};
  const { paper_work } = kycData?.data || {};
  const proposalStatus = companyStatus === "Hold" ? "Under review" : companyStatus || "Under review";
  const underReview =
    paper_work?.documents?.length > 0 ||
    (isIssuer && +stepCompleted >= 6) ||
    (isInvestor && +stepCompleted >= 7);
  let statusText = "Start KYC";
  if (isIssuer && companyStatus !== "Approved")
    statusText = `Business Proposal Is ${proposalStatus}`;
  else if (isKycCompleted) statusText = " KYC Approved";
  else if (+stepCompleted >= 1)
    statusText = underReview ? "KYC under review." : "Resume KYC";
  return (
    <span className={isKycCompleted ? "text-light" : "text-light"}>
      {statusText}
    </span>
  );
};

export const validateFirstName = (fName) => {
  const errMsg = !fName
    ? validationMessages.fName
    : !/^[a-z][a-z\s]*$/i.test(fName)
    ? validationMessages.allowAlphabets
    : "";
  return errMsg;
};

export const validateLastName = (lName) => {
  const errMsg = !lName
    ? validationMessages.lName
    : !/^[a-z][a-z\s]*$/i.test(lName)
    ? validationMessages.allowAlphabets
    : "";
  return errMsg;
};

export const validateName = (Name) => {
  const errMsg = !Name
    ? validationMessages.name
    : !/^[a-z][a-z\s]*$/i.test(Name)
    ? validationMessages.allowAlphabets
    : "";
  return errMsg;
};

export const validateEmail = (Email) => {
  const errMsg = !Email
    ? validationMessages.emailReq
    : !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Email)
    ? validationMessages.validEmail
    : "";
  return errMsg;
};

export const validateMobile = (Mobile) => {
  const errMsg = !Mobile
    ? validationMessages.phoneReq
    : // ? validationMessages.phoneReq
    /\D/g.test(Mobile)
    ? // : !/^(\+\d{1,3}[- ]?)?\d{10}$/.test(Mobile)
      validationMessages.validMobile
    : Mobile.length < 10
    ? validationMessages.validMobile
    : Mobile.length > 13
    ? validationMessages.validMobile
    : "";
  return errMsg;
};
export const validateMobileByCountryCode = (Mobile,validationNumberRegex) => {

  const errMsg = !Mobile
    ? validationMessages.phoneReq
     : !validationNumberRegex?.test(Mobile)
     ? validationMessages.validMobile
    // : Mobile.length < 10
    // ? validationMessages.validMobile
    // : Mobile.length > 13
    // ? validationMessages.validMobile
     : "";

  return errMsg;
};

export const validatePassWord = (password) => {
  const errMsg = !password
    ? validationMessages.passwReq
    : !/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,32}$/.test(
        password
      )
    ? validationMessages.validPass
    : "";
  return errMsg;
};

export const validateCPassWord = (cpassword, password) => {
  const errMsg = !cpassword
    ? validationMessages.cpasswReq
    : cpassword !== password
    ? validationMessages.notMatchPassw
    : "";
  return errMsg;
};

export const reqLoginPassWord = (password) => {
  const errMsg = !password ? validationMessages.passwReq : "";
  return errMsg;
};

export const reqCurrentPassWord = (password) => {
  const errMsg = !password ? validationMessages.currentasswReq : "";
  return errMsg;
};

export const validatetitle = (title) => {
  const errMsg = !title ? validationMessages.require : "";
  return errMsg;
};

export const validateRequire = (val) => {
  const errMsg = !val ? validationMessages.require : "";
  return errMsg;
};

export const validateNoMandatory = (val) => {
  const errMsg =   (val.high_Risk_Investment == 'NO' || val.investing_Entirely == 'NO' || val.equivesta == "NO" || val.investment_Quickly == "NO") ? validationMessages.no_mandatory : "";
  return errMsg;
};
export const validateDocSign = (val,docSignStatus) => {
  const errMsg =   (val == 'YES' && !docSignStatus) ? validationMessages.docSign : "";
  return errMsg;
};

export const validatePalteform = (pId) => {
  const errMsg = !pId ? validationMessages.require : "";
  return errMsg;
};

export const validateDocumentArr = (docArr = []) => {
  let err1 = {};

  var validateAllElement = (ele, index) => {
    err1[`idType${index}`] = validateRequire(ele?.idType);
    err1[`image${index}`] = validateRequire(ele?.image);
  };
  if (docArr?.length > 0) {
    docArr.map((ele, index) => validateAllElement(ele, index));
  }

  return { ...err1 };
};

export const validateImage = (img) => {
  const errMsg = !img ? validationMessages.require : "";
  return errMsg;
};

export const ValidateImageSize = (selectedFile) => {
  let errMsg = "";
  if (selectedFile) {
    const maxSize = 10 * 1024 * 1024;
    if (selectedFile.size > maxSize) {
      errMsg = "Image size is too large (max 10 MB).";
    } else {
      errMsg = "";
    }
  } else {
    errMsg = "Please select an image to upload.";
  }
  return errMsg;
};
export const isLessThan20MB = (files) => {
  const maxSize = 20 * 1024 * 1024;
  let totalSize = 0;

  if (!files || !files.length) {
    return true;
  }

  if (Object.values(files || {})?.length > 1) {
    for (const file of files) {
      totalSize += file.size;
      if (totalSize > maxSize) {
        return false;
      }
    }
  } else {
    totalSize = files[0].size;
    if (totalSize > maxSize) {
      return false;
    }
  }

  return true;
};

export const validateTextField = (val) => {
  const errMsg = !val
    ? validationMessages.require
    : !/^[a-z][a-z\s]*$/i.test(val)
    ? validationMessages.allowAlphabets
    : "";
  return errMsg;
};

export const validateTnc = (val) => {
  const errMsg = !val ? validationMessages.require : "";
  return errMsg;
};

export const validateblogDescription = (val) => {
  const errMsg = !val
    ? validationMessages.require
    : // : !/^[a-z][a-z\s]*$/i.test(val)
    // ? validationMessages.allowAlphabets
    val.length > 600
    ? validationMessages.blogDescriptionMax
    : "";
  return errMsg;
};
export const validateProjDoc_link = (val = []) => {
  const errMsg = val.length <= 0 ? validationMessages.require : "";
  return errMsg;
};

export const validateStartTime = (sTime) => {
  let errMsg = "";
  const currentDate = new Date();
  const selectedFromDate = new Date(sTime);
  if (sTime) {
    if (
      currentDate.getDate() === selectedFromDate.getDate() &&
      !(currentDate.getTime() < selectedFromDate.getTime())
    ) {
      errMsg = validationMessages.invalTime;
    }
  } else {
    errMsg = "please select start date";
  }

  return errMsg;
};

export const validateEndTime = (sTime, eTime) => {
  let errMsg = "";
  const currentDate = new Date();
  const selectedFromDate = new Date(sTime);
  const selectedToDate = new Date(eTime);
  if (eTime) {
    if (
      currentDate.getDate() === selectedToDate.getDate() &&
      !(currentDate.getTime() < selectedToDate.getTime())
    ) {
      errMsg = validationMessages.invalTime;
    }
    if (selectedFromDate.getTime() === selectedToDate.getTime()) {
      errMsg = validationMessages.sameTime;
    }
    if (!(selectedFromDate.getTime() < selectedToDate.getTime())) {
      errMsg = validationMessages.invalTime;
    }
  } else {
    errMsg = "please select the end date";
  }
  return errMsg;
};

export const isValid = (errors) => {
  const traverseErrors = (errors) => {
    let countError = 0;
    for (let key in errors) {
      if (typeof errors[key] === "object") {
        countError += traverseErrors(errors[key]);
      } else {
        countError += errors[key] ? 1 : 0;
      }
    }
    return countError;
  };

  return traverseErrors(errors) === 0;
};

export const validateCountry = (country) => {
  const errMsg = !country ? validationMessages.country : "";
  return errMsg;
};

export const validateProvince = (province) => {
  const errMsg = !province ? validationMessages.province : "";
  return errMsg;
};

export const validateCity = (city) => {
  const errMsg = !city ? validationMessages.city : "";
  return errMsg;
};

export const validateStreetNumber = (streetNumber) => {
  const errMsg = !streetNumber ? validationMessages.streetNumber : "";
  return errMsg;
};
export const validateSoftCap = (softVal, hardVal = 0) => {
  const errMsg = !softVal
    ? validationMessages.require
    : +softVal > +hardVal
    ? validationMessages.greaterSoft
    : "";
  return errMsg;
};
export const validateHardCap = (softVal = 0, hardVal) => {
  const errMsg = !hardVal
    ? validationMessages.require
    : +softVal > +hardVal
    ? validationMessages.lessHard
    : "";
  return errMsg;
};
export function truncateSlice(str, maxLength) {
  if (str?.length > maxLength) {
    return str.slice(0, maxLength) + "..." + str.slice(-5);
  } else {
    return str;
  }
}
export function firstLaterCapitalize(str = "") {
  if (str?.length) {
    return str[0]?.toLocaleUpperCase() + str.slice(1)?.toLocaleLowerCase();
  } else {
    return str;
  }
}
export const validateStreetName = (streetName) => {
  const errMsg = !streetName ? validationMessages.streetName : "";
  return errMsg;
};
export const validateOTP = (otp) => {
  const errMsg = !otp
    ? validationMessages.otpEnter
    : otp && otp.length < 6
    ? validationMessages.validOtp
    : "";
  return errMsg;
};
export const validatePostalCode = (postalCode) => {
  const errMsg = !postalCode ? validationMessages.postalCode : "";
  return errMsg;
};

export const validateDob = (dob) => {
  const errMsg = !dob ? validationMessages.dob : "";
  return errMsg;
};

export const validatePep = (pep) => {
  const errMsg = !pep ? validationMessages.pep : "";
  return errMsg;
};

export const validateAddress = (address1) => {
  const errMsg = !address1 ? validationMessages.address1 : "";
  return errMsg;
};

export const validateOccupation = (occupation) => {
  const errMsg = !occupation ? validationMessages.occupation : "";
  return errMsg;
};

export const validateEmploymentName = (employment_name) => {
  const errMsg = !employment_name ? validationMessages.employment_name : "";
  return errMsg;
};

export const validateEmployerStreetName = (employer_street_name) => {
  const errMsg = !employer_street_name
    ? validationMessages.employer_street_name
    : "";
  return errMsg;
};

export const validateEmployerSuiteNumber = (employer_suite_number) => {
  const errMsg = !employer_suite_number
    ? validationMessages.employer_suite_number
    : "";
  return errMsg;
};

export const validateEmployerCountry = (employer_country) => {
  const errMsg = !employer_country ? validationMessages.employer_country : "";
  return errMsg;
};

export const validateEmployerCity = (employer_city) => {
  const errMsg = !employer_city ? validationMessages.employer_city : "";
  return errMsg;
};

export const validateEmployerProvinance = (employer_provinance) => {
  const errMsg = !employer_provinance
    ? validationMessages.employer_provinance
    : "";
  return errMsg;
};

export const validateEmployerPostalCode = (postalcode) => {
  const errMsg = !postalcode ? validationMessages.postalcode : "";
  return errMsg;
};

export const validateTotalIncome = (total_Income) => {
  const errMsg = !total_Income ? validationMessages.total_Income : "";
  return errMsg;
};

export const validateNetWorth = (net_Worth) => {
  const errMsg = !net_Worth ? validationMessages.net_Worth : "";
  return errMsg;
};

export const validateNetFinnancialAssets = (net_Financial_Assests) => {
  const errMsg = !net_Financial_Assests
    ? validationMessages.net_Financial_Assests
    : "";
  return errMsg;
};

export const validateJurisdiction = (jurisdiction) => {
  const errMsg = !jurisdiction ? validationMessages.jurisdiction : "";
  return errMsg;
};

export const validateInvestmentKnowledge = (investment_Knowledge) => {
  const errMsg = !investment_Knowledge
    ? validationMessages.investment_Knowledge
    : "";
  return errMsg;
};

export const validateRiskTolerance = (risk_Tolerance) => {
  const errMsg = !risk_Tolerance ? validationMessages.risk_Tolerance : "";
  return errMsg;
};

export const validateInvestementTimeHorizon = (investment_Time_Horizon) => {
  const errMsg = !investment_Time_Horizon
    ? validationMessages.investment_Time_Horizon
    : "";
  return errMsg;
};

export const validateInvestementObjectives = (investment_Objectives) => {
  const errMsg = !investment_Objectives
    ? validationMessages.investment_Objectives
    : "";
  return errMsg;
};

export const validateTotalAmounInvested = (total_Amount_Invested) => {
  const errMsg = !total_Amount_Invested
    ? validationMessages.total_Amount_Invested
    : "";
  return errMsg;
};

export const validateEmailTCP = (email) => {
  const errMsg = !email
    ? validationMessages.emailReq
    : !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
    ? validationMessages.validEmail
    : "";
  return errMsg;
};

export const validateHighRiskInvest = (high_Risk_Investment) => {
  const errMsg = !high_Risk_Investment
    ? validationMessages.high_Risk_Investment
    : "";
  return errMsg;
};

export const validateInvestingEntirely = (investing_Entirely) => {
  const errMsg = !investing_Entirely
    ? validationMessages.investing_Entirely
    : "";
  return errMsg;
};
export const validateEquivesta = (equivesta) => {
  const errMsg = !equivesta ? validationMessages.equivesta : "";
  return errMsg;
};
export const validateQuickInvest = (investment_Quickly) => {
  const errMsg = !investment_Quickly
    ? validationMessages.investment_Quickly
    : "";
  return errMsg;
};
export const validateInfoInvest = (riskData) => {
  let errMsg
  if(!riskData?.info_Investment){
   errMsg = validationMessages.info_Investment 
  }else if(riskData?.info_Investment === 'YES' && riskData?.isDocSign === false){
    errMsg = validationMessages.docSign
  }else {
    errMsg = ""
  }
  return errMsg;
};
export const validateOntarioSecCommission = (ontario_Securities_Commision) => {
  const errMsg = !ontario_Securities_Commision
    ? validationMessages.ontario_Securities_Commision
    : "";
  return errMsg;
};

export const validatePaperWorkName = (first_name) => {
  const errMsg = !first_name ? validationMessages.first_name : "";
  return errMsg;
};
export const validatePaperWorkInvestorString = (investorString) => {
  const errMsg = !investorString ? validationMessages.investorString : "";
  return errMsg;
};

export const validateCountryCode = (countryCode) => {
  const errMsg = !countryCode ? validationMessages.countryCode : "";
  return errMsg;
};

export const validateLinkedin = (value = "") => {
  if (!value) return "Please enter a LinkedIn URL";
  return value.startsWith("https://www.linkedin.com/")
    ? ""
    : "Please enter a valid LinkedIn URL";
};

export const getPriceNumberFormate = (price = "") => {
  return `${new Intl.NumberFormat("en-US").format(price)}`;
};

export const getDotColorImage = (color) => {
  return (
    <span className="dotViolet">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <circle cx="6" cy="6" r="6" fill={color} />
      </svg>
    </span>
  );
};

// export const downLoadLink = (url = "", name = "") => {
//   if (url) {
//     const downloadLink = document.createElement("a");
//     downloadLink.href = url;
//     downloadLink.target = "_blank";
//     downloadLink.download = name ? name : "document";
//     document.body.appendChild(downloadLink);
//     downloadLink.click();
//     document.body.removeChild(downloadLink);
//   }
// };


export const downLoadLink = async (url = "", fileName = "document") => {
  if (!url) {
    
    return;
  }
  try {
    const response = await fetch(url);
    if (!response.ok) {
      
      return;
    }
    
    const blob = await response.blob();

    if(blob.type.includes('pdf') || blob.type.includes('csv')){
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName ? fileName : `download.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }else {
      toast.error("Sorry, This file is empty!")
    }
  
  } catch (error) {
    // toast.error(`Error downloading PDF from ${url}:`, error)
    
  }
};

export const validateURLInput = (value) => {
  const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
  let errMsg = "";

  if (value.trim() !== "") {
    if (!urlPattern.test(value)) {
      errMsg = "Invalid URL format";
    }
  }
  return errMsg;
};

export const validateYouTubeURL = (value) => {
  const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
  let errMsg = "";

  if (value.trim() !== "") {
    if (!urlPattern.test(value) || !value.includes("youtube.com")) {
      errMsg = "Invalid Youtube URL";
    }
  }
  return errMsg;
};
