/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { apiURl } from "../../../store/actions";
import { SetloaderData } from "../../../store/reducer";
import { API } from "../../../apiwrapper";
import { BASECONFIG } from "../../../config";
import { toast } from "react-toastify";

function DocumentCard({documentType,totalLength}) {
  const dispatch = useDispatch();
  const [data, setData] = useState({});

  const fetchListingData = async () => {
    try {
      let url = `${apiURl.updateUser}/work/total`;
      dispatch(SetloaderData(true));
      const data = await API({
        base_url: BASECONFIG.REACT_APP_BASEURL_V2,
        url: `${url}`,
        method: "GET",
        dispatch,
      });

      if (data?.status || data?.status === "true") {
        setData(data);
      } else {
        setData({});
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      dispatch(SetloaderData(false));
    }
  };

  useEffect(() => {
    fetchListingData();
  }, []);
  return (
    <>
      {/* <div className="container">
<div className="">
      <div className="row mt-2 documentCard">
        <div className="col-md-3">
          <div
            className="card "
            style={{ backgroundColor: "rgb(168 226 238)" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title documentNumberDigit">{data?.requestedCount || 0}</h5>
              <h6 className="card-subtitle mb-2 mt-5 documentNumberDigit">Document Requested</h6>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div
            className="card "
            style={{ backgroundColor: "rgb(241 192 151)" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title documentNumberDigit">{data?.receivedCount || 0}</h5>
              <h6 className="card-subtitle  mb-2  mt-5 documentNumberDigit">Document Receive</h6>
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div
            className="card"
            style={{ backgroundColor: "rgb(220 243 118)" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title documentNumberDigit">{data?.approvedCount || 0}</h5>
              <h6 className="card-subtitle  mb-2  mt-5 documentNumberDigit">Pending Approval</h6>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div
            className="card "
            style={{backgroundColor: "rgb(103 203 157)" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title documentNumberDigit" >1</h5>
              <h6 className="card-subtitle  mb-2  mt-5 documentNumberDigit">Document to be expired</h6>
            </div>
          </div>
        </div>
        </div>
      </div>
      </div> */}
      <div className="container">
        <div className="row my-5" id="table-contexual">
          <div className="col-md-12">
            <div className="">
              <div className="row newDocMobileResponsive">
                <div className="col-md-3">
                <div className="card">
                    <div className="card-body text-center">
                      <div className="custome-document_mgt">
                      <img src="../Image/document.png" alt="" style={{width:"70px"}}/>
                      <div className="mx-4">
                        <h6 className="card-subtitle">{documentType === 'Received' ? 'Total Documents Received' : documentType === 'Request' ? 'Total Document Requests' : 'Total Document'}</h6>
                        <h5 className="card-title mt-2">{documentType === 'Received' ? (data?.recievedCount||0) : documentType === 'Request' ? (data?.requestedCount||0) : (totalLength || 0)}</h5>
                        </div>
                      </div>
                    </div>
                  </div> 

                  {/* <div class="Detailbox1 ">
                    <div class="my-3 mx-3 totalDocument">
                    <img style={{paddingBottom:"24px"}} src="../Image/blocked.png" alt="" />
                      <p className="dashboardTotalColor">{documentType === 'Received' ? 'Total Documents Received' : documentType === 'Request' ? 'Total Document Requests' : 'Total Document'} <br></br><span>
                        {documentType === 'Received' ? (data?.receivedCount||0) : documentType === 'Request' ? (data?.requestedCount||0) : (totalLength || 0)}
                        </span></p>
                    </div>
                  </div> */}

                </div>
                <div className="col-md-3">

                <div className="card">
                  <div className="card-body text-center">
                    <div className="custome-document_mgt">
                    
                    <img src="../Image/document.png" alt="" style={{width:"70px"}}/>
                    
                    <div className="mx-4">
                      <h6 className="card-subtitle">{documentType === 'Received' ? 'No Action Required' : documentType === 'Request' ? 'Submitted' : 'Expiring in Week'}</h6>
                      <h5 className="card-title mt-2">{documentType === 'Received' ? (data?.noActionCount||0) : documentType === 'Request' ? (data?.submisttedDocByUserCount||0) : (data?.expiringInWeek||0)}</h5>
                      </div>
                    </div>
                  </div>
                </div> 

                {/* <div class="Detailbox ">
                    <div class="my-3 mx-3 totalDocument">
                    <img src="../Image/blocked.png" alt="" />
                    <p className="dashboardTotalColor"  >{documentType === 'Received' ? 'No Action Required' : documentType === 'Request' ? 'Submitted' : 'Expiring in Week'} <br></br>
                    <span>
                    {documentType === 'Received' ? (data?.noActionCount||0) : documentType === 'Request' ? (data?.submisttedDocByUserCount||0) : (data?.expiringInWeek||0)}
                      </span>
                    </p>
                    </div>
                  </div> */}

                </div>
                <div className="col-md-3">
                <div className="card">
                    <div className="card-body text-center">
                      <div className="custome-document_mgt">
                      <img src="../Image/document.png" alt="" style={{width:"70px"}}/>
                      <div className="mx-4">
                        <h6 className="card-subtitle">{documentType === 'Received' ? 'Action Pending' : documentType === 'Request' ? 'Pending' : 'Send for Renewal'}</h6>
                        <h5 className="card-title mt-2">{documentType === 'Received' ? (data?.ActionPendingCount||0) : documentType === 'Request' ? (data?.pendingDocByUserCount||0) : (data?.sendForRenewalCount||0)}</h5>
                        </div>
                      </div>
                    </div>
                  </div> 


                {/* <div class="Detailbox">
                    <div class="my-3 mx-3 totalDocument">
                    <img src="../Image/blocked.png" alt="" />
                    <p className="dashboardTotalColor"  >{documentType === 'Received' ? 'Action Pending' : documentType === 'Request' ? 'Pending' : 'Send for Renewal'} <br></br><span>
                    {documentType === 'Received' ? (data?.ActionPendingCount||0) : documentType === 'Request' ? (data?.pendingDocByUserCount||0) : (data?.sendForRenewalCount||0)}
                      </span></p>
                    </div>
                  </div> */}

                </div>
                {documentType !== 'Request' &&
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body text-center">
                      <div className="custome-document_mgt">
                      <img src="../Image/document.png" alt="" style={{width:"70px"}}/>
                        <div className="mx-4">
                        <h6 className="card-subtitle">{documentType === 'Received' ? 'Action Done' : documentType === 'Request' ? 'Rejected' : 'Renewal Approved'}</h6>
                        <h5 className="card-title mt-2">{documentType === 'Received' ? (data?.ActionDoneCount||0) : documentType === 'Request' ? (data?.cancelledDoc||0) : (data?.RenewalAppCount||0)}</h5>
                        </div>
                      </div>
                    </div>
                  </div> 

                {/* <div class="Detailbox">
                    <div class="my-3 mx-3 totalDocument">
                    <img src="../Image/blocked.png" alt="" />
                    <p className="dashboardTotalColor"  >{documentType === 'Received' ? 'Action Done' : documentType === 'Request' ? 'Rejected' : 'Renewal Approved'} <br></br><span>
                    {documentType === 'Received' ? (data?.ActionDoneCount||0) : documentType === 'Request' ? (data?.cancelledDoc||0) : (data?.RenewalAppCount||0)}
                      </span></p>
                    </div>
                  </div> */}
                </div>
                   }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DocumentCard;