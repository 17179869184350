import axios from "axios";
import { API } from "../apiwrapper";
import { BASECONFIG } from ".";
import { apiURl } from "../store/actions";
import { LogoutUser, SetAuthUserData } from "../store/reducer/authUser";
import { LogoutGoogleUser } from "../store/reducer/authGoogleUser";
import { SetKycData, SetPreKycData } from "../store/reducer/kycData";
import { SetAddNewProjectData } from "../store/reducer";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";

let userAccessToken = null;


const getTokenData = ()=>{

  let getAccessToken = Cookies.get('accessToken');

  if (getAccessToken) {
    try {
      userAccessToken = JSON.parse(getAccessToken);
  
    } catch (error) {
      userAccessToken = null; // Provide a default value or handle the error as needed
    }
  } else {
    userAccessToken = null; // Handle the case where getToken is undefined
  }

}

export const getCurrentLocation = async () => {
  try {
    const res = await axios.get("https://ipinfo.io?token=11534cdaa8e4de");
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

// export const logOutApi = (obj) => async (dispatch) => {
//   try {
//     let navigate = useNavigate(); // Move useNavigate inside the function
//     let location = await getCurrentLocation();
//     // let payload = {
//     //   location: location,
//     //   user_id: obj?._id,
//     //   section: "Last logged out",
//     //   status: "Success",
//     //   service: "Sign Out",
//     //   activity: "Log Out",
//     //   email: obj?.email?.id,
//     //   password: "",
//     //   userType: obj?.isIssuer
//     //     ? "Issuer"
//     //     : obj?.isInvestor
//     //     ? "Investor"
//     //     : "User",
//     // };

//     let payload = {
//      "userId": obj?._id?obj?._id:'',
//       "accessToken": userAccessToken, // get cookies access token
//       "removeAll" : "ALL" 
//     }

//     let url = `${apiURl.userLogout}`;

//     const response = await API({
//       base_url: BASECONFIG.REACT_APP_BASEURL_V2,
//       url: url,
//       method: "POST",
//       body: { ...payload },
//       dispatch,
//     });

//     if (response.status || response.status === "true") {
//       toast.success(response?.message)
//       dispatch(LogoutUser({}));
//       dispatch(SetPreKycData({}));
//       dispatch(LogoutGoogleUser({}));
//       dispatch(SetAuthUserData({}));
//       dispatch(SetKycData({}));
//       dispatch(SetAddNewProjectData({}));
//       Cookies.remove('accessToken');
//       Cookies.remove('token');
//        navigate("/login");
//       localStorage.clear();
//     }

 

//   } catch (error) {}
// };
export const logOutApi = (obj) => async (dispatch) => {
  try {
    getTokenData()
    // Assuming you are calling this from within a React component
    // const navigate = useNavigate(); 
    // const location = await getCurrentLocation();
    const payload = {
      userId: obj?._id || '',
      accessToken: userAccessToken
      // removeAll: "ALL"
    };
    console.log(payload)

    const url = `${apiURl.userLogout}`;

    const response = await API({
      base_url: BASECONFIG.REACT_APP_BASEURL_V2,
      url: url,
      method: "POST",
      body: payload,
      dispatch,
    });
    if (response.status === "true" || response.status === true  || response?.statusCode == 403 ||response?.statusCode == 200  ) {
      toast.success(response?.message);
      Cookies.remove('accessToken');
      Cookies.remove('token')
      localStorage.clear();
      dispatch(SetPreKycData({}));
      dispatch(LogoutGoogleUser({}));
      dispatch(SetAuthUserData({}));
      dispatch(SetKycData({}));
      dispatch(SetAddNewProjectData({}));
   ;     
    }
  } catch (error) {
   
    toast.error('Logout failed. Please try again.');
  }
};